import React, { useEffect, useState } from 'react'
import "../../style/css/style.min.css"
import "aos/dist/aos.css"
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Layout from "../../components/layout"
import '../../utils/font-awesome'
import FrontOfficeTheme from '../../style/globalTheme'
import { css } from "@emotion/react"
import ClipLoader from "react-spinners/SquareLoader"
import Box from '@mui/material/Box'
import { navigate } from 'gatsby-link';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;




const Hub = ({ imageUrl, eventData, isSubscribed }) => {


    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#000000")

    const [count, setCount] = useState(0)
    const [seconds, setSeconds] = useState(null)
    const [minutes, setMinutes] = useState(null)
    const [hours, setHours] = useState(null)
    const [days, setDays] = useState(null)
    const [showDate, setShowDate] = useState(false)
    const [states, setStates] = useState([])
    const [message, setMessage] = useState('')
    const [eventEnded, setEventEnded] = useState(false)
    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {

        const newStates = states.concat(isSubscribed)
        setStates(newStates)

        if (newStates.includes('CYCLE-NOTFOUND') && newStates.includes('EVENT-NOTFOUND'))
            setMessage("Vous n'êtes pas inscrit à cet événement avec ce compte. Veillez vous connecter avec le bon compte ou acheter cet événement avec ce compte.")

        if (newStates.includes('CYCLE-FOUND') || newStates.includes('EVENT-FOUND'))
            setMessage("")

    }, [isSubscribed])


    let AOS;
    useEffect(() => {

        setIsMounted(true)

        const AOS = require("aos");
        AOS.init({
            once: true,
        });
    }, []);

    useEffect(() => {
        if (AOS) {
            AOS.refresh();
        }
    })

    useEffect(() => {

        if (eventData) {

            const now = new Date()
            const time = parseInt(now.getTime() / 1000)
            const remainingSeconds = eventData.date - time

            if (remainingSeconds < - 60 * eventData.duree)
                setEventEnded(true)

        }



    }, [count, eventData])

    useEffect(() => {
        if (!eventData)
            setShowDate(false)
        if (eventData) {

            const now = new Date()
            const time = parseInt(now.getTime() / 1000)
            const timer = setTimeout(() => {
                const remainingSeconds = eventData.date - time

                setShowDate(true)




                if (remainingSeconds < - 60 * eventData.duree)
                    setShowDate(false)
                else {
                    const nbDays = parseInt(remainingSeconds / 3600 / 24)
                    const nbHours = parseInt((remainingSeconds - nbDays * 3600 * 24) / 3600)
                    const nbMinutes = parseInt((remainingSeconds - nbDays * 3600 * 24 - nbHours * 3600) / 60)
                    const nbSeconds = remainingSeconds - nbDays * 3600 * 24 - nbHours * 3600 - nbMinutes * 60
                    setDays(nbDays)
                    setHours(nbHours)
                    setMinutes(nbMinutes)
                    setSeconds(nbSeconds)
                    setCount((count + 1) % 10)


                    // http://locahosst:8000/hub/?inscription=e8a97471-8d8b-4d49-8daa-090106232a8d

                    if (remainingSeconds < 15 * 60 && eventData.outilUrl && remainingSeconds > - 60 * eventData.duree) {
                        const eventUrl = eventData?.outilUrl

                        if (eventUrl) {
                            const regex = /(https:\/\/)\S+/
                            let finalUrl = 'https://www.pekoia.com'
                            if (eventUrl.match(regex))
                                finalUrl = eventUrl
                            else
                                finalUrl = 'https://' + eventUrl


                            console.log('-- DEBUG --')
                            console.log(finalUrl)


                            window.location.href = finalUrl
                        }

                    }


                }
            }, 1000);
            return () => clearTimeout(timer)
        }


    }, [count, eventData])




    // srcset="i/content_26_video_poster@2x.jpg 2x" 

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={FrontOfficeTheme}>
                <Layout >


                    {/* <!-- Content 26 --> */}
                    <section className="pt-100 pb-100 bg-light content_26">
                        <div className="container px-xl-0">
                            <div className="row justify-content-center">
                                <div className="col-xl-10 col-lg-11">

                                    <div className="row flex-row-reverse justify-content-between">

                                        <div className="col-md-7 mb-3 mb-md-0">
                                            <div className="relative poster" data-aos="fade-down" data-aos-delay="250">

                                                <img src={imageUrl} alt="" className="img-fluid radius10" />
                                            </div>
                                        </div>
                                        <div className="col-md-4 h-800">
                                            <div className="mb-20"> {message}</div>
                                            <div className="mt-15 inner h-80">


                                                {!showDate && <Box textAlign="center"><ClipLoader color={color} loading={loading} css={override} size={80} /></Box>}
                                                {showDate && <h2 className="f-20 color-heading">
                                                    {eventData?.title1}{eventData?.title2 ? "-" : ""} {eventData?.title2}
                                                </h2>}
                                                {showDate && <h2 className="f-20 color-heading mt-20">
                                                    {"dans "}
                                                    {days > 1 ? days + ' jours ' : ''}
                                                    {[0, 1].includes(days) ? days + ' jour ' : ''}
                                                    {hours > 1 ? hours + 'h ' : ''}
                                                    {[0, 1].includes(hours) ? hours + 'h ' : ''}
                                                    {minutes > 1 ? minutes + 'mn ' : ''}
                                                    {[0, 1].includes(minutes) ? minutes + 'mn ' : ''}
                                                    {seconds > 1 ? seconds + 's' : ''}
                                                    {[0, 1].includes(seconds) ? seconds + 's' : ''}
                                                </h2>}


                                                <h2 className="f-22 mt-40">
                                                    {showDate && !eventEnded && "L'événement n'est pas encore ouvert"}
                                                    { eventEnded && "Evénement terminé."}
                                                   
                                                </h2>
                                                <div className="mt-15 color-heading text-adaptive">
                                                    {!eventEnded && isMounted &&  eventData && "Le lien vers l'événement en ligne sera accessible au plus tôt une heure avant l'événement. Nous vous remercions donc de vous reconnecter  via ce même lien quelques minutes avant le début de l'événement."}
                                                </div>
                                                <button onClick={() => navigate('/')} className="btn mt-60 sm border-gray color-main f-16">
                                                    Retour à Pekoia
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </Layout>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
export default Hub
