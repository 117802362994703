import React, { useEffect, useContext, useState } from "react"
import { API, graphqlOperation } from "aws-amplify"
import { AuthContext } from "../context/general"
import  { Auth ,Amplify} from 'aws-amplify';
import { transactionsByClientId, eventsbyCycle, clientByCognitoId, getEvent } from '../graphql/queries'
import Hub from '../pagesComponents/hub/hub'
import { useQueryParam, StringParam } from "use-query-params"

import { Location } from '@reach/router'
import queryString from 'query-string'
import PropTypes from "prop-types"
import { Analytics } from 'aws-amplify'

import awsConfig from '../aws-exports'
import { InvertColorsSharp } from "@mui/icons-material";

Amplify.configure(awsConfig)

const handleFound = (eventId, setImageUrl, setEventData, setIsSubscribed) => {
   
    setIsSubscribed(1)
    API.graphql(graphqlOperation(getEvent, { id: eventId }))
        .then(({ data }) => {

           
            const url = 'https://www.pekoia.com/static/L' + data.getEvent?.imageKey + '-500-' + data.getEvent?.imageETag500 + '.jpg'
            setImageUrl(url)
            setEventData(data.getEvent)
           
                    

            



        })

}






const Ics = () => {

    const [imageUrl, setImageUrl] = useState(null)
    const [eventData, setEventData] = useState(null)
    const [foo, setFoo] = useQueryParam("inscription", StringParam)
        

    const { authState, authActions } = useContext(AuthContext)
   
    const [isAuthenticating, setIsAuthenticating] = useState(true)
    const [isAuthenticated, userHasAuthenticated] = useState(false)
    const [isSubscribed, setIsSubscribed] = useState(0)
    const [message, setMessage] = useState('')
    const [gotConnection, setGotConnection] = useState(false)
   
    const onLoad = async () => {
        try {
            await Auth.currentSession();
            userHasAuthenticated(true);
        }
        catch (e) {
            if (e !== 'No current user') {
                console.log('GROSS MOSTAKE')
            }
        }

        setIsAuthenticating(false);
    }

    useEffect(() => {
        onLoad()


    }, [])

    useEffect(()=>{

        if (process.env.NODE_ENV === "production"){
    
          Analytics.record({ name: 'hubPageVisit' })
    
        }
    
       
    
      },[])
    


    const getConnection = (eventId) => {

        if ( !gotConnection){

       


        Auth.user && Auth.currentAuthenticatedUser()
            .then(data => {


                const cognitoId = data?.attributes?.sub

                if (cognitoId) {

                    API.graphql(graphqlOperation(clientByCognitoId, { cognitoId: cognitoId }))
                        .then(({ data }) => {
                          
                            const result = data?.clientByCognitoId?.items
                          
                            if (result?.length) {
                                const clientId = result[0].id


                                // avoid double calls
                                setGotConnection(true)

                                API.graphql(graphqlOperation(transactionsByClientId, { clientId: clientId, limit: 100000000 }))
                                    .then(({ data }) => {
                                       
                                        const cyclesTX = data?.transactionsByClientId?.items
                                            .filter(elem => elem.cycleId && elem.cycleId != "undetermined" && ['INITIATING', 'PROCESSING', 'SETTLED'].includes(elem.status))
                                            .filter(elem => elem.lemonwayId)

                                        //API.graphql(graphqlOperation(eventsbyCycle, { cycleId: cycleId }))
                                        cyclesTX.forEach(tx => {
                                            const cycleId = tx.cycleId
                                            API.graphql(graphqlOperation(eventsbyCycle, { cycleId: cycleId }))
                                                .then(({ data }) => {
                                                    const eventsIds = data.eventsbyCycle.items
                                                        .map(event => event.id)

                                                    const found = eventsIds.find(event => event === eventId)
                                                    if (found){
                                                        handleFound(eventId, setImageUrl, setEventData, setIsSubscribed)
                                                        setIsSubscribed('CYCLE-FOUND') 
                                                    }
                                                    else {
                                                        setIsSubscribed('CYCLE-NOTFOUND') 
                                                    }
                                                })

                                        })

                                    })
                                    .catch(err => console.log(err))

                                API.graphql(graphqlOperation(transactionsByClientId, { clientId: clientId, limit: 100000000 }))
                                    .then(({ data }) => {

                                        const transactions = data?.transactionsByClientId?.items

                                        const found = transactions.find(transaction => transaction.eventId === eventId)

                                        if (!found) {
                                            setIsSubscribed('EVENT-NOTFOUND') // not subscribed

                                        }
                                        if (found) {
                                            setIsSubscribed('EVENT-FOUND')
                                            handleFound(eventId, setImageUrl, setEventData, setIsSubscribed)

                                        }

                                    })

                            }


                        })
                        .catch(err => console.log(err))


                }



            })

        }
    }


    useEffect(() => {


        
        console.log('@SEARCH')
        console.log(foo)



        const eventId = foo




        if (!isAuthenticating && !isAuthenticated) {
            authActions.toggleLoginModal(3)
        }

        if (!isAuthenticating && isAuthenticated) {

            getConnection(eventId)
        }



    }, [isAuthenticating, isAuthenticated])


    useEffect(() => {
        if (isSubscribed === 2) {
            setMessage("Vous n'êtes pas inscrit à l'événement avec cet identifiant. Veuillez changer de compte ou vous inscrire sur celui-ci.")
        }
    }, [isSubscribed])


    useEffect(() => {
        const eventId = foo





        if (!isAuthenticating && authState.user?.id)
            getConnection(eventId)



    }, [authState])




    return (

        <Hub imageUrl={imageUrl} message={message} eventData={eventData} isSubscribed={isSubscribed} />

    )
}





export default Ics